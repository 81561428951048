import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Container from 'components/container';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <Container>
      <h1>We can&apos;t find the page you are looking for.</h1>
      <p>Sorry, it looks like the page you are looking for does not exist.</p>
      <p>
        Try using the main menu or navigating back to the&nbsp;
        <Link to="/">homepage</Link>
        .
      </p>
    </Container>
  </Layout>
);

export default NotFoundPage;
